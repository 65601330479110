<template>
  <div class="pr" >
	  	<div class="HomeNumBer" id="estimate">
	  		<div class="container flex">
	  			<div class="HomeNumLi fadeInLeft animated" >3 Quick <span>Ways</span></div>
		  		<div class="HomeNumLi fadeInLeft animated" data-wow-delay="0.3s">Over 200,000 <span>Customers</span></div>
		  		<div class="HomeNumLi fadeInRight animated">20 Years <span>Experience</span></div>
		  		<div class="HomeNumLi fadeInRight animated" data-wow-delay="0.3s">5 Star <span>Services</span></div>
	  		</div>
	  	</div>
	  	<!--Get a quick estimate-->
			<div class="HomeBody BodyPadd" >
	  		<div class="container">
	  			<h1 class="LittleTxt fadeInLeft animated">
	  				Get a quick estimate
	  			</h1>
	  			<div class="TypeSel fadeInUp animated">
		  				<a href="javascript:;" @click="TypeSelHread('home')" data-txt="home"><span class="check" :class="SelHouse=='home'?'active':''"></span>Move a home</a>
		  				<a href="javascript:;" @click="TypeSelHread('office')" data-txt="office"><span class="check" :class="SelHouse=='office'?'active':''"></span>Move an office</a>
		  			</div>
		  		<div class="SelTypeTab fadeInUp animated">
						<transition mode="out-in">
				  			<househome @func="getHomeType" v-if="SelHouse=='home'"/> 
				  			<housebusiness @func="getBusinessType" v-if="SelHouse=='office'"/> 
				  	</transition>	
		  		</div>
	  			<div class="TypeBottomTxt fadeInUp animated" v-if="$store.state.processdata.GetItem">
	  				Estimate: ${{$store.state.processdata.GetItem.HourlyPrice}}/h
	  			</div>
	  			<div class="TypeBottomLabel fadeInUp animated" v-if="$store.state.processdata.GetItem">
	  				<a href="javascript:;">{{$store.state.processdata.GetItem.Mover}} movers</a>
	  				<!-- <a href="javascript:;">{{$store.state.processdata.GetItem.vechileNumber}} {{$store.state.processdata.GetItem.VechileModel}} truck</a> -->
	  				<a href="javascript:;">{{$store.state.processdata.GetItem.VechileModel}}</a>
	  				<a href="javascript:;">{{$store.state.processdata.GetItem.MinHour}}-{{$store.state.processdata.GetItem.MaxHour}} hours</a>
	  			</div>
			    <a href="javascript:;" class="BottomBtn fadeInUp animated" @click="GetGo" v-if="$store.state.processdata.GetItem">
				  	<img src="/img/invoice.png">Get a quick estimate
				  </a>
	    	</div>
	  	</div>
	  	<!--Our reviews-->
			<div class="HomeReviews pr">
				<div class="HomeReviewsLeft fadeInLeft animated">
					<h1>Our reviews</h1>
					<p>We Have Thousands Of Satisfied Customers!</p>
				</div>
				<div class="HomeReviewsRight fadeInRight animated">
						<swiper :options="swiperOption">
							<swiper-slide class="HomeSlide">
								<div>
									<p>"Customer service was great, low wait times and very helpful. The quote, job updates and receipt also came really fast.The movers arrived early. They were very friendly and efficient, even though my apartment was a bit tricky. Will definitely use this company for future jobs."</p>
									<div class="SlideBottom">
										<img src="/img/Oval.png">
										<div class="SlideBottomTxt">
											<h1>
												Georgia Gonzalez
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>
								</div>	
							</swiper-slide>
							<swiper-slide>
								<div>
									<p>" Boys were very professional and knew exactly how to pack my large, unusual-shaped furniture for the perfect fit in their truck. They were efficient and well-prepared with tools to help dismantle items that wouldn't fit through the doors. I highly recommend this service!"</p>
									<div class="SlideBottom">
										<img src="/img/Oval2.png">
										<div class="SlideBottomTxt">
											<h1>
												Randy Nguyen
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>
								</div>	
							</swiper-slide>
							<swiper-slide>
								<div>
									<p>" Just used their relocation service yesterday and was really impressed by their professionalism, punctuality and efficiency. Maybe a bit pricy but it's definitely worth it. Highly recommend!"</p>
									<div class="SlideBottom">
										<img src="/img/Oval3.png">
										<div class="SlideBottomTxt">
											<h1>
												Hulda Evans
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>
								</div>	
							</swiper-slide>
							<swiper-slide>
								<div>
									<p>" Excellence is an understatement. The move was stressing me out a tad as I was moving from Melbourne to Ballarat. Imover(this is the third time I've benefited from their help) delivered above and beyond my expectations. Just brilliant. Happily recommend imover to anyone looking at moving home."</p>
									<div class="SlideBottom">
										<img src="/img/ovalnew1.png">
										<div class="SlideBottomTxt">
											<h1>
												Mayme Hunter
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>
								</div>	
							</swiper-slide>
							<swiper-slide>
								<div>
									<p>" Imover was moving a large marble table and chairs, due to the narrow shape of my house, they figured out how to dis-assemble the table and re-assemble it. They finished the job half hour faster than quoted. Everything was done professionally with no damage to the table or my house."</p>
									<div class="SlideBottom">
										<img src="/img/ovalnew6.png">
										<div class="SlideBottomTxt">
											<h1>
												Antonio Huff
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>
								</div>	
							</swiper-slide>
							<swiper-slide>
								<div>
									<p>" Outstanding company, the best moving experience I've had to date. They arrived on time with a crew of four and got to work immediately. My furniture was disassembled quickly and professionally, every piece wrapped and padded-ready for a safe transport. I would give them 10 stars if I could."</p>
									<div class="SlideBottom">
										<img src="/img/ovalnew2.png">
										<div class="SlideBottomTxt">
											<h1>
												Richard Evans
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>
								</div>	
							</swiper-slide>							
							<swiper-slide>
								<div>
									<p>" HIGHLY RECOMMENDED!!!!! They were Very professional, attentive to our furniture, no scratches on the walls, fast and accommodating to our needs.. the team leader Matt was very polite and I highly recommend this moving company."</p>
									<div class="SlideBottom">	
										<img src="/img/ovalnew3.png">
										<div class="SlideBottomTxt">
											<h1>
												Isaac Figueroa
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>	
								</div>	
							</swiper-slide>
							<swiper-slide>
								<div>
									<p>" I will never use another moving company again. iMover Furniture Removals is the best from start to finish. They were super responsive and professional throughout the process of getting a quote, getting my questions answered, and scheduling my move."</p>
									<div class="SlideBottom">
										<img src="/img/ovalnew4.png">
										<div class="SlideBottomTxt">
											<h1>
												Terry Houston
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>
								</div>	
							</swiper-slide>
							<swiper-slide>
								<div>
									<p>" The guys were extremely polite, using my name to address me which was appreciated. They helped me position furniture, attached mirrors, hose to washing machine and were most obliging. This is our second time of using iMover. I would highly recommend them to my friends. Thank you so much."</p>
									<div class="SlideBottom">
										<img src="/img/ovalnew5.png">
										<div class="SlideBottomTxt">
											<h1>
												Don French
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>
								</div>	
							</swiper-slide>
							<!--<swiper-slide>
								<div>
									<p>" As a business owner, iMover professional service help us make the moving office become a easy task. I will recommend their service and also promotion their business to our clients."</p>
									<div class="SlideBottom">
										<img src="/img/ovalnew6.png">
										<div class="SlideBottomTxt">
											<h1>
												Antonio Huff
											</h1>
											<ul class="Star">
												<li></li>
												<li></li>
												<li></li>
												<li></li>
												<li></li>
											</ul>
										</div>
									</div>
								</div>	
							</swiper-slide>-->
						</swiper>
						<div class="swiper-pagination Homepag" slot="pagination"></div>
	  		</div>
			</div>
	  	<!--Home movers Melbourne-->
	  	<div class="HomeMovers">
	  		<div class="container">
	  			<h1 class="fadeInLeft animated">
					Large enough to handle, small enough to care 
	  			</h1>
	  			<p class="fadeInRight animated">
					iMover is well known in the moving industry. Our undeniable reputation brings most pre-existing customers back.
	  			</p>
	  			<div class="HomeMoversList fadeInUp animated">
	  				<div class="MoverListLi">
							<img src="/img/about-us-img6.jpeg">
							<div class="MoverTxt">Moving house melbourne</div>
	  				</div>
	  				<div class="MoverListLi">
							<img src="/img/about-us-img8.jpeg">
							<div class="MoverTxt">Office relocations</div>
	  				</div>
	  				<div class="MoverListLi">
							<img src="/img/best_removalists_melbourne_03.jpeg">
							<div class="MoverTxt">Furniture removalists</div>
	  				</div>
	  				<div class="MoverListLi">
							<img src="/img/video01-1.png">
							<div class="MoverTxt">Packing & Unpacking services</div>
	  				</div>
	  			</div>
	  			<div class="HomeMoversIcon fadeInUp animated">
	  				<a href="javascript:;" @click="goClick('/about')">
	  					<img src="/img/busicon_03.jpg">Learn more
	  				</a>
	  			</div>
	  		</div>
	  	</div>
	  	<div class="Homequote">
  		<div class="container">
	  		<div class="HomequoteLi ">
	  			<div class="HomequoteLeft fadeInLeft animated">
	  				<div>
	  					<h1>
		  					Moving house Melbourne specials
		  				</h1>
		  				<p> Home Move Furniture Removalists Melbourne.</p>
		  				<a href="javascript:;" @click="goquote" class="HomeQuoteBtn">
		  					<img src="/img/busicon_03.jpg">Request a quote
		  				</a>
	  				</div>
	  			</div>
	  			<img class="fadeInRight  animated" src="/img/homebottom.jpeg">
	  		</div>
	  	</div>	
  	</div>
</div>
</template>
<script>		
	import househome from '@/components/househome.vue'
	import housebusiness from '@/components/housebusiness.vue'
	import { swiper, swiperSlide } from 'vue-awesome-swiper'
	import api from '@/api';
	export default {
	  name: 'Homebody',
	  props: {
	    msg: String
	  },
	  components: {
	    swiper,
	    swiperSlide,
	    househome,
    	housebusiness
	  },
	  data(){
	  	return{
				Layout: 1, 
				SelHouse:'home',
				GetHouseType:this.$store.state.processdata.GetItem?this.$store.state.processdata.GetItem.Code:'A1',
	  		swiperOption:{}
	  	}
	  },
	  created(){
	  	if (this._isMobile()) {
	     this.swiperOption={
	  			slidesPerView: 1,
		      spaceBetween: 10,
		      pagination: {
		        el: '.swiper-pagination',
		        clickable: true,
		      },
	      };
	   } else {	      
	     this.swiperOption={
	  			slidesPerView: 3.5,
		      spaceBetween: 30,
		      pagination: {
		        el: '.swiper-pagination',
		        clickable: true,
		      },
	      };
	    }
	  },
	  mounted(){
	  },
	  methods:{
		goClick(e){
			this.$router.push({path:e})
		},
	  	_isMobile() {
			    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
				 return flag;
			},
	  	TypeSelHread(e){
	  		this.SelHouse=e;
	  		if(this.SelHouse=='home'){
	  			this.Layoutenew='A1';
	  		}else{
	  			this.Layoutenew='B1';
	  		}
	  	},
	  	getBusinessType(e){//商业类型选择
	  		this.GetHouseType=e.Code;
	  		this.GetItem=e;
	  		var sel={
	  			SelHouse:this.SelHouse,
	  			GetHouseType:this.GetHouseType,
	  			GetItem:this.GetItem,
	  		}
				this.$store.dispatch('getMsgFormSonFun',sel);
				this.$forceUpdate();
	  	},
	  	getHomeType(e){//住宅类型选择
	  		this.GetHouseType=e.Code;
	  		this.GetItem=e;
	  		var sel={
	  			SelHouse:this.SelHouse,
	  			GetHouseType:this.GetHouseType,
	  			GetItem:this.GetItem,
	  		}
				this.$store.dispatch('getMsgFormSonFun',sel);
				this.$forceUpdate();
	  	},
	  	GetGo(){
//	    this.$router.push({ path: '/getquote', query: { SelHouse: this.SelHouse,GetHouseType:this.GetHouseType}});	  	
				var sel={
	  			SelHouse:this.SelHouse,
	  			GetHouseType:this.GetHouseType,
	  			GetItem:this.GetItem?this.GetItem:this.$store.state.processdata.GetItem,
	  		}
				this.$store.dispatch('getMsgFormSonFun',sel);
				this.$store.dispatch('setisshowFun',true);
				document.body.scrollTop = document.documentElement.scrollTop = 0;
	  		this.$router.push({path:'/getquote/processfrom',params:{sel:sel}})
	  	},
	  	goquote(){
	  		document.body.scrollTop = document.documentElement.scrollTop = 0;
	  		this.$router.push({path:'/getquote/processfirst'})
	  	}
	  }
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
		.swiper-container {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #f5f5f5;

      /* Center slide text vertically */
      display: -webkit-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
@import '/css/swiper.min.css';
</style>
