<template>
	<div>
		<img src="../../public/img/iconleft.png" class="IconLeft" v-if="Left" @click="leftblack()">
		<img src="../../public/img/iconright.png" class="IconRight" v-if="Right" @click="rightblack()">
	</div>
</template>

<script>
	export default {
		name: 'HelloWorld',
		props: {
			msg: String
		},
		data() {
			return {
				Left: false,
				Right: false,
				state: {},
				stateIndex: 0,
				Rightrouter: '',
				Leftrouter: '',
				layid:0,
				layidright:0,
				spark: false, //更新编辑地点使用
				sparkright: false, //更新编辑地点使用
				setisshowstate:false,
			}
		},
		methods: {
			detectUrl(name) {
				var that = this;
				that.Left = false;
				that.Right = false;
				that.spark = false;
				that.sparkright = false;
				that.setisshowstate = false;
				that.stateIndex = that.state.parkingindex;
				switch(name) {
					case 'processfirst':
						if(that.state.getlist.length>0) {
							that.Right = true;
							that.layidright=2;
							that.Rightrouter = 'processfrom';
						}
						break;
					case 'processfrom':
						if(that.state.processdata.SelHouse) {
							that.Left = true;
							that.layid=1;
							that.Leftrouter = 'processfirst';
						}
						if(that.state.Timedata.pickedDate) {
							that.Right = true;							
							that.layidright=5;
							that.Rightrouter = 'processdate';
						}
						break;
					case 'processdate':
						if(that.state.getlist[0].house) {
							that.Left = true;
							that.layid=2;
							that.Leftrouter = 'processfrom';
						}
						if(that.state.moredata) {
							that.Right = true;
							that.layidright=6;
							that.Rightrouter = 'processfullpice';
						}
						break;
					case 'processfullpice':
						if(that.state.Timedata.pickedDate) {
							that.Left = true;							
							that.layid=5;
							that.setisshowstate = true;
							that.Leftrouter = 'processdate';
						}
						if(that.state.getlist[0].rice) {
							that.Right = true;
							that.layidright=7;
							that.Rightrouter = 'processparking';
						}
						break;
					case 'processparking': //距离页面箭头
						if(that.state.parkingindex > 0) {
							that.spark = true;
							if((that.state.getlist[that.stateIndex].floor > 1 && that.state.getlist[that.stateIndex].house == 'Apartment') || (that.state.getlist[that.stateIndex].floor > 1 && that.state.processdata.SelHouse == 'office')) {
								if(that.state.getlist[that.state.parkingindex].docklandsstate) {
									that.stateIndex = that.stateIndex - 1;
									that.Left = true;						
									that.layid=14;
									that.Leftrouter = 'processdocklands';
								}
							} else {
								that.stateIndex = that.stateIndex - 1;
								that.Left = true;					
								that.layid=8;
								that.Leftrouter = 'processhousefloor';
							}
						} else {
							if(that.state.moredata) {
								that.Left = true;				
								that.layid=6;
								that.Leftrouter = 'processfullpice';
							}
						}
						if(that.state.getlist[that.state.parkingindex].floor != 0) {
							that.Right = true;				
							that.layidright=8;
							that.Rightrouter = 'processhousefloor';
						}
						break;
					case 'processhousefloor': //楼层页箭头判断
						that.Left = true;
						that.layid=7;
						that.Leftrouter = 'processparking';
						if((that.state.getlist[that.state.parkingindex].floor > 1 && that.state.getlist[that.state.parkingindex].house == 'Apartment') || (that.state.getlist[that.state.parkingindex].floor > 1 && that.state.processdata.SelHouse == 'office')) {
							if(that.state.getlist[that.state.parkingindex].docklandsstate) {
								that.Right = true;				
								that.layidright=14;
								that.Rightrouter = 'processdocklands';
							}
						} else {
							that.stateIndex = that.stateIndex + 1;
							that.sparkright = true;
							if(that.stateIndex < that.state.getlist.length && that.state.getlist[that.stateIndex].floor != 0) {
								that.Right = true;
								that.layidright=7;
								that.Rightrouter = 'processparking';
							} else {
								if(that.state.processdata.SelHouse == 'office' && that.state.getBusinessItem) { //判断是否返回商业item选择还是住宅item选择
									that.Right = true;
									that.layidright=12;
									that.Rightrouter = 'processbusinessitems';
								}
								if(that.state.processdata.SelHouse == 'home' && that.state.getItem) {
									that.Right = true;
									that.layidright=11;
									that.Rightrouter = 'processitems';
								}
							}
						}
						break;
					case 'processdocklands':
						that.Left = true;
						that.layid=8;
						that.Leftrouter = 'processhousefloor';
						that.stateIndex = that.stateIndex + 1;
						that.sparkright = true;
						if(that.stateIndex < that.state.getlist.length && that.state.getlist[that.stateIndex].floor != 0) {
							that.Right = true;
							that.layidright=7;
							that.Rightrouter = 'processparking';
						} else {
							if(that.state.processdata.SelHouse == 'office' && that.state.getBusinessItem) { //判断是否返回商业item选择还是住宅item选择
								that.Right = true;
								that.layidright=12;
								that.Rightrouter = 'processbusinessitems';
							}
							if(that.state.processdata.SelHouse == 'home' && that.state.getItem) {
								that.Right = true;
								that.layidright=11;
								that.Rightrouter = 'processitems';
							}
						}
						break;
					case 'processitems':
						that.Left = true;
						that.stateIndex=that.state.parkingindex-1;
						that.spark = true;
						that.setisshowstate = true;
						if(that.state.getlist[that.state.parkingindex-1].docklandsstate) { //判断下是否返回电梯选择页								
							that.Leftrouter = 'processdocklands';
							that.layid=14;
						} else {
							that.Leftrouter = 'processhousefloor';
							that.layid=8;
						}
						if(that.state.contact.name) {
							that.Right = true;
							that.layidright=13;
							that.Rightrouter = 'processcontact';
						}
						break;
					case 'processbusinessitems':
						that.Left = true;
						that.stateIndex=that.state.parkingindex-1;
						that.spark = true;
						that.setisshowstate = true;
						if(that.state.getlist[that.state.parkingindex-1].docklandsstate) { //判断下是否返回电梯选择页					
							that.Leftrouter = 'processdocklands';
							that.layid=14;
						} else {
							that.Leftrouter = 'processhousefloor';
							that.layid=8;
						}
						if(that.state.contact.name) {
							that.Right = true;
							that.layidright=13;
							that.Rightrouter = 'processcontact';
						}
						break;
					case 'processcontact':
						if(!that.state.getBusinessItem&&!that.state.getItem){
							that.Left = true;
							that.layid=6;
							that.Leftrouter = 'processfullpice';
							return;
						}
						if(that.state.processdata.SelHouse == 'office' && that.state.getBusinessItem) { //判断是否返回商业item选择还是住宅item选择							
							that.Left = true;
							that.layid=12;
							that.Leftrouter = 'processbusinessitems';
						}
						if(that.state.processdata.SelHouse == 'home' && that.state.getItem) {
							
							that.Left = true;
							that.layid=11;
							that.Leftrouter = 'processitems';
						}
						break;
				}
			},
			leftblack() {
				var that=this;
				if(that.spark) {
					that.$store.dispatch('getparkingindexFun', this.stateIndex);
				}
				if(that.setisshowstate){
					that.$store.dispatch('setisshowFun', this.setisshowstate);
				}
				this.$store.dispatch('getLayoutFun',this.layid);
				this.$router.push({name: this.Leftrouter,params:{blackstatus:true}});
			},
			rightblack() {
				var that=this;
				if(that.sparkright) {
					this.$store.dispatch('getparkingindexFun', this.stateIndex);
				}
				this.$store.dispatch('getLayoutFun',this.layidright);
				this.$router.push({name:this.Rightrouter,params:{blackstatus:true}});
			},
		},
		mounted() {
			this.state = this.$store.state;
			this.detectUrl(this.$route.name);
		},
		watch: {
			"$route.name": function(name) {
				this.detectUrl(name);
			},
			"state.getlist": function(data) {
				if(data.length==0){
					this.Right = false;
				}
			}
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	
</style>